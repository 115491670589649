import Auth from "@alphatekas/pwr_core/dist/auth";
import CMD from "@alphatekas/pwr_core/dist/cmd";
import Forceplate from "@alphatekas/pwr_core/dist/forceplate";
import Leveller from "@alphatekas/pwr_core/dist/leveller";
import Squatter, { Squat } from "@alphatekas/pwr_core/dist/squatter";
import Storage from "@alphatekas/pwr_core/dist/storage";
import { createClient } from "@supabase/supabase-js";

console.log("__INIT__");

const hz = 434.0277777777777;

const cmd = new CMD();

const forceplate = new Forceplate({
    hz,
    active_s: 0.5,
    active_w: 10,
    auto_tare: true,
    deps: {
        cmd
    }
});

forceplate.on("active", active => console.log("ACTIVE: " + active));

const leveller = new Leveller({
    seconds: 2,
    variance: 1,
    w_cutoff: 5,
    deps: {
        forceplate
    }
});

const squatter = new Squatter({
    w_cutoff: 3,
    v_cutoff: 0.1,
    t_min: 0.75,
    deps: {
        forceplate,
        leveller
    }
});

// Do this in a better way?
squatter.configure_model();

const URL = "https://pqeiqiyrlenkcddedcpm.supabase.co";
const KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBxZWlxaXlybGVua2NkZGVkY3BtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDExMDg2NDAsImV4cCI6MjAxNjY4NDY0MH0.ZE1N_NXXb-5nR0JFSzAgbZKjLT6dttCNWNq99bHKGrs";

const db = createClient(URL, KEY, {
    auth: {
        autoRefreshToken: false, // Prevent invalidating remote token
        storageKey: "alphabase_session", // Use custom storage key
        persistSession: false, // Do not persist the session
    },
});

const auth = new Auth({
    deps: {
        cmd,
        forceplate,
        supabase: db,
    },
});

const storage = new Storage({
    kind: "squat_set",
    keep: {
        min: forceplate.hz, // 1 second
        max: forceplate.hz * 60 * 5, // 5 minutes
    },
    deps: {
        forceplate: forceplate,
        auth,
    },
    init: () => ({
        level: undefined as number,
        reps: [] as Squat[],
        stop_percentage: +localStorage.getItem("squat_stop_percentage") || undefined,
        results_timeout: +localStorage.getItem("squat_results_timeout") || undefined,
    }),
});

export {
    auth,
    db,
    cmd,
    forceplate,
    leveller,
    squatter,
    storage,
};
